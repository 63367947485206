@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;

  font-family: 'BonnBF-Regular', 'BonnBF-Medium', -apple-system,
    BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', '', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'BonnBF-Regular';
  src: url(./fonts/BonnBF-Regular.woff2) format('woff2'),
    url(./fonts/BonnBF-Regular.woff) format('woff'),
    url(./fonts/BonnBF-Regular.otf) format('opentype'),
    url(./fonts/BonnBF-Regular.ttf) format('ttf');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'BonnBF-Medium';
  src: url(./fonts/bonn-bf-medium.otf) format('opentype');
  font-weight: normal;
}

.buy-star {
  position: relative;

  &:last-child {
    margin: 0;
  }

  p:after {
    bottom: -16px;
    content: "";
    display: block;
    height: 1px;
    left: 50%;
    position: absolute;
    background-image: linear-gradient(
      270deg,
      rgba(255, 0, 96, 0) 0%,
      rgba(17, 203, 237, 1) 50%,
      rgba(255, 0, 96, 0) 100%
    );
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
}

.header-nav-button {
  padding: 1rem 0;
  position: relative;

  &:last-child {
    margin: 0;
  }

  p:after {
    bottom: 0px;
    content: "";
    display: block;
    height: 1px;
    left: 50%;
    position: absolute;
    background-image: linear-gradient(
      270deg,
      rgba(255, 0, 96, 0) 0%,
      rgba(255, 0, 96, 1) 50%,
      rgba(255, 0, 96, 0) 100%
    );
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }

  p:hover:after {
    width: 100%;
    left: 0;
  }
}